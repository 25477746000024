@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --color-primary: #851a92;
        --color-primary-100: #fce5ff;
        --color-primary-500: #de6aec;
        --color-primary-800: #cf35e0;

        --color-accent-1: #f5b826;
        --color-accent-1-10: rgba(255, 241, 207, 0.1);
        --color-accent-1-50: rgba(255, 241, 207, 0.5);
        --color-accent-1-100: #fff1cf;
        --color-accent-1-400: rgba(245, 184, 38, 0.8);
        --color-accent-1-500: #ffdb85;

        --color-accent-2: #3fce81;
        --color-accent-2-100: #daffeb;
        --color-accent-2-500: #9bfcc7;
        --color-accent-2-700: #76ffb5;

        --color-accent-3: #ce2843;
        --color-accent-3-100: #fcd1d8;
        --color-accent-3-500: #fc667f;

        --color-accent-4: #35c4f1;
        --color-accent-4-100: #e3f5fb;
        --color-accent-4-500: #98e5fe;
        --color-accent-4-800: #0084f3;

        --color-accent-5: #ffa26c;
        --color-accent-5-100: #ffe8da;
        --color-accent-5-500: #ffa26c;

        --color-neutral-1: #0c0b1d;
        --color-neutral-1-100: #f5f6fa;
        --color-neutral-1-150: rgba(245, 246, 250, 0.5);
        --color-neutral-1-170: rgba(245, 246, 250, 0.7);
        --color-neutral-1-180: rgba(245, 246, 250, 0.8);
        --color-neutral-1-200: #e9eaf0;
        --color-neutral-1-300: #cacbd6;
        --color-neutral-1-330: rgba(202, 203, 214, 0.3);
        --color-neutral-1-400: #b8b8b8;
        --color-neutral-1-500: #212838;
        --color-neutral-1-550: rgba(33, 40, 56, 0.5);
        --color-neutral-1-600: #05050f;
        --color-neutral-1-620: rgba(5, 5, 15, 0.2);
        --color-neutral-1-630: rgba(5, 5, 15, 0.3);
        --color-neutral-1-640: rgba(5, 5, 15, 0.4);
        --color-neutral-1-650: rgba(5, 5, 15, 0.5);
        --color-neutral-1-700: #353d49;
        --color-neutral-1-750: #020710;
        --color-neutral-1-800: #d9d9d9;
        --color-neutral-1-850: rgba(12, 11, 29, 0.85);
        --color-neutral-1-900: #0f131c;
    }

    @font-face {
        font-family: "Antonio";
        src: url("../assets/fonts/Antonio-Regular.ttf") format("truetype");
    }

    @font-face {
        font-family: "Antonio";
        src: url("../assets/fonts/Antonio-Bold.ttf") format("truetype");
        font-weight: bold;
    }

    @font-face {
        font-family: "Antonio";
        src: url("../assets/fonts/Antonio-SemiBold.ttf") format("truetype");
        font-weight: 400;
    }

    @font-face {
        font-family: "Zingakon";
        src: url("../assets/fonts/Zingakon.ttf") format("truetype");
    }
}

@layer components {
    .b-button {
        @apply h-[46px] w-full outline-none rounded-[3px] bg-accent-1 text-lg font-bold leading-[21px] text-neutral-1;
    }

    .b-button:disabled {
        @apply bg-neutral-1-300 text-neutral-1-200;
    }

    .b-button:hover:not(:disabled) {
        @apply text-neutral-1-100;
    }

    .b-button-light {
        @apply h-[46px] w-full outline-none rounded-[3px] bg-accent-1-500 text-lg font-bold leading-[21px] text-accent-1;
    }

    .b-button-light:disabled {
        @apply bg-neutral-1-300 text-neutral-1-200;
    }

    .b-button-border {
        @apply h-[46px] w-full outline-none rounded-[3px] border-[2px] border-accent-1 bg-black text-lg font-bold leading-[21px] text-accent-1 disabled:border-neutral-200 disabled:text-neutral-1-200;
    }

    .b-button-border:disabled {
        @apply border-neutral-200 text-neutral-1-200;
    }

    .b-input {
        @apply h-[36px] w-full rounded-[5px] border border-[#ADADAD] bg-white px-[17px] text-14 placeholder:text-neutral-1-300;
    }

    .b-input:focus {
        @apply outline-none;
    }

    .b-input:disabled {
        @apply bg-neutral-1-200 text-neutral-1-300;
    }

    .b-input.invalid {
        @apply border-[2px] border-accent-3 text-accent-3;
    }

    .b-input.valid {
        @apply border-[2px] border-primary text-primary;
    }

    .b-inset-center {
        @apply absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transform;
    }

    .b-flex-center {
        @apply flex items-center justify-center;
    }

    .b-scrollbar::-webkit-scrollbar {
        width: 6px;
    }

    .b-scrollbar::-webkit-scrollbar-track {
        background: var(--color-neutral-1-800);
        border-radius: 6px;
    }

    .b-scrollbar::-webkit-scrollbar-thumb {
        background-color: var(--color-accent-1);
        border-radius: 6px;
    }

    .b-scrollbar.green::-webkit-scrollbar-thumb {
        background-color: var(--color-accent-2);
    }

    .b-scrollbar.purple::-webkit-scrollbar-thumb {
        background-color: var(--color-primary);
    }

    .b-scrollbar.beer::-webkit-scrollbar-thumb {
        background-color: rgba(245, 184, 38, 0.2);
    }

    .b-scrollbar.beer::-webkit-scrollbar-track {
        background: var(--color-neutral-1-500);
        border-radius: 6px;
    }

    .b-scrollbar.beer.dark::-webkit-scrollbar {
        width: 3px;
    }

    .b-scrollbar.beer.dark::-webkit-scrollbar-track {
        background: var(--color-neutral-1-620);
        border-radius: 6px;
    }

    .b-scrollbar.silver::-webkit-scrollbar-thumb {
        background-color: rgba(202, 202, 202, 0.2);
    }

    .b-scrollbar.silver::-webkit-scrollbar-track {
        background: var(--color-neutral-1-500);
        border-radius: 6px;
    }

    .fix-w-283 {
        width: 283px !important;
    }

    .clip-selection-name {
        clip-path: polygon(0 0, 100% 0, 92% 100%, 8% 100%);
    }

    .b-text-win {
        background: linear-gradient(180deg, #EEC467 20.05%, #FFFFCF 37.3%, #FFDE7A 55.13%, #CEA346 72.07%, #E77F08 95.72%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
}

@import "multi-datepicker.css";
@import "slider.css";
@import "modal.css";
@import "animation.css";
@import "faq.css";

body {
    font-family: "Inter";
    font-weight: normal;
    font-style: normal;
    color: black;
}

body::-webkit-scrollbar {
    width: 8px;
    background-color: #02050e;
}

body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background-color: #7c7c7c;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    border-radius: 10px;
    background-color: #02050e;
}

button,
input,
optgroup,
select,
textarea {
    font-family: "Inter";
    font-weight: normal;
    font-style: normal;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input.checkbox:checked + div {
    @apply bg-black;
}

input.checkbox:checked + div svg {
    @apply block;
}

input[type="radio"] + label span {
    @apply border-neutral-1-300 bg-white;
}

input[type="radio"]:checked + label span {
    @apply bg-accent-1 !important;
    box-shadow: 0px 0px 0px 3px white inset !important;
}

input[type="radio"]:not(:disabled) + label span:hover,
input[type="radio"]:not(:disabled) + label:hover span {
    @apply border-accent-1 bg-white;
}

input[type="radio"]:checked + label {
    @apply text-black font-semibold;
}

input[type="radio"]:disabled + label {
    @apply text-neutral-1-300;
}

input[type="radio"]:disabled + label span {
    @apply bg-neutral-1-200;
}

input.switch:checked ~ .dot {
    @apply translate-x-full bg-accent-1;
}

input:-webkit-autofill {
    appearance: menulist-button;
    background-image: none !important;
    background-color: transparent;
    color: fieldtext !important;
}

/* LABEL */
.b-lbl-primary {
    @apply rounded-[3px] bg-primary px-[9px] py-[7px] text-sm font-medium leading-[10px] text-white;
}

.b-lbl-information {
    @apply rounded-[3px] bg-accent-4 px-[9px] py-[7px] text-sm font-medium leading-[10px] text-white;
}

.b-lbl-error {
    @apply rounded-[3px] bg-accent-3 px-[9px] py-[7px] text-sm font-medium leading-[10px] text-white;
}

.b-lbl-success {
    @apply rounded-[3px] bg-accent-2 px-[9px] py-[7px] text-sm font-medium leading-[10px] text-white;
}

.b-lbl-warning {
    @apply rounded-[3px] bg-accent-1 px-[9px] py-[7px] text-sm font-medium leading-[10px] text-white;
}

.b-lbl-other {
    @apply rounded-[3px] bg-accent-5 px-[9px] py-[7px] text-sm font-medium leading-[10px] text-white;
}

/* LIGHT */
.b-lbl-primary-light {
    @apply rounded-[3px] bg-primary-100 px-[9px] py-[7px] text-sm font-medium leading-[10px] text-primary;
}

.b-lbl-information-light {
    @apply rounded-[3px] bg-accent-4-100 px-[9px] py-[7px] text-sm font-medium leading-[10px] text-accent-4;
}

.b-lbl-error-light {
    @apply rounded-[3px] bg-accent-3-100 px-[9px] py-[7px] text-sm font-medium leading-[10px] text-accent-3;
}

.b-lbl-success-light {
    @apply rounded-[3px] bg-accent-2-100 px-[9px] py-[7px] text-sm font-medium leading-[10px] text-accent-2;
}

.b-lbl-warning-light {
    @apply rounded-[3px] bg-accent-1-100 px-[9px] py-[7px] text-sm font-medium leading-[10px] text-accent-1;
}

.b-lbl-other-light {
    @apply rounded-[3px] bg-accent-5-100 px-[9px] py-[7px] text-sm font-medium leading-[10px] text-accent-5;
}

/* END LABEL */

/* TEXT LINK */

.b-link {
    @apply text-xs font-medium leading-[13px] text-primary-500;
}

.b-link:disabled,
.b-link.disabled {
    @apply text-primary;
}

/* TEXT LINK */

/* TITLE */
.c-title {
    @apply text-24 font-bold uppercase leading-[29px] text-black lg:text-40 lg:leading-[56px];
}

.scrollbar_custom::-webkit-scrollbar {
    @apply w-1 bg-neutral-1-650;
}

.scrollbar_custom::-webkit-scrollbar-thumb {
    @apply bg-neutral-1-330;
}

.border-gradient-bottom {
    border-image-slice: 1;
    border-image-source: linear-gradient(to bottom, #851a92 0%, rgba(133, 26, 146, 0) 100%);
}

.search-border {
    background-image: linear-gradient(#ffffff, #ffffff), linear-gradient(43.33deg, #FFC90A 14.52%, #FEA542 50.2%, #851A92 85.89%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border: 1px solid transparent;
    outline: none;
    text-indent: 22px;
    border-radius: 44px;
    font-size: 14px;
}

.search-border.recently {
    border-radius: 20px 20px 0 0;
}

.search-border.recently-results {
    border-radius: 0 0 20px 20px;
    border-top: 0;
}

.search-border-1 {
    background: #FFFFFF;
    border: 0.75px solid #5A5A5A;
    border-radius: 5px;
    text-indent: 22px;
}


@media (max-width: 768px) {
    .search-border-1 {
        background-image: linear-gradient(#ffffff, #ffffff), linear-gradient(43.33deg, #FFC90A 14.52%, #FEA542 50.2%, #851A92 85.89%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        border: 1px solid transparent;
        outline: none;
        text-indent: 22px;
        border-radius: 44px;
    }

    .hover-gradient-1 {
        border-image-slice: 1;
        border-image-source: linear-gradient(to bottom, #851a92 0%, rgba(133, 26, 146, 0) 100%);
    }

    .hover-gradient-2 {
        border-image-slice: 1;
        border-image-source: linear-gradient(180deg, #35c4f1 0%, rgba(53, 196, 241, 0) 100%);
    }
}

.hover-gradient-1:hover {
    border-image-slice: 1;
    border-image-source: linear-gradient(to bottom, #851a92 0%, rgba(133, 26, 146, 0) 100%);
}

.border-gradient-right {
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #35053a, #851a92 100%);
}

.border-gradient-right-1 {
    border-image-slice: 1;
    border-image-source: linear-gradient(90deg, #0D4B5F 0%, #38C9F7 100%);
}

.hover-gradient-2:hover {
    border-image-slice: 1;
    border-image-source: linear-gradient(180deg, #35c4f1 0%, rgba(53, 196, 241, 0) 100%);
}

.b-text-win {
    background: linear-gradient(180deg, #EEC467 20.05%, #FFFFCF 37.3%, #FFDE7A 55.13%, #CEA346 72.07%, #E77F08 95.72%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.b-border-container-win {
    border-image-slice: 1;
    border-image-source: linear-gradient(179.91deg, #F5B826 0.94%, rgba(245, 184, 38, 0) 98.78%) !important;
}

.b-border-container-lose {
    border-image-slice: 1;
    border-image-source: linear-gradient(180deg, #949494 0%, rgba(151, 151, 151, 0) 100%) !important;
}

.swiper-pagination-bullet {
    @apply mx-[7px] block h-[7px] w-[7px] rounded-full border-none bg-neutral-1-200;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    @apply mx-[6px] h-[9px] w-[9px] bg-accent-1;
}

.b-category-name {
    @apply relative w-fit pl-[30px] pb-[10px] font-third text-sm leading-[25px];
}

.b-category-name::before {
    @apply absolute top-0 left-0 h-[25px] w-[25px] bg-[url('../assets/imgs/icon/hashtag.svg')] bg-contain bg-no-repeat content-[''];
}

.b-category-name::after {
    @apply absolute bottom-0 left-0 h-[10px] w-[95%] bg-[url('../assets/imgs/icon/vector.svg')] bg-contain bg-no-repeat content-[''];
}

.b-border-purple-gradient {
    background-image: linear-gradient(var(--color-neutral-1-500), var(--color-neutral-1-500)), linear-gradient(180deg, #750289 0%, rgba(117, 2, 137, 0.36) 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border: 1px solid transparent;
}

.b-bg-purple-gradient {
    background: linear-gradient(180deg, #0F131C 0%, #3B0B41 100%);
}

.b-account-border-gradient {
    background-image: linear-gradient(var(--color-neutral-1-500), var(--color-neutral-1-500)), linear-gradient(180deg, #F5B826 0%, #750289 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border: 3px solid transparent;
}

.b-debate-line-clip-path {
    clip-path: polygon(1% 0%, 100% 0%, 99% 100%, 0% 100%);
}

.b-debate-left {
    background: linear-gradient(90deg, rgba(133, 26, 146, 0) 0%, #851A92 78.9%);
}

.b-debate-right {
    background: linear-gradient(90deg, rgba(53, 196, 241, 0) 0%, #35C4F1 98.25%);
    transform: rotate(-180deg);
}

.b-gradient-creative-lord {
    background: linear-gradient(180deg, #05050F 0%, rgba(5, 5, 15, 0) 100%);
    transform: rotate(-180deg);
}

.b-pb-6 {
    padding-bottom: 24px !important;
}

.b-step-bg {
    background: linear-gradient(90deg, #851A92 0%, #B70CCC 100%);
}

.b-progress-bar {
    background: linear-gradient(90deg, rgba(133, 26, 146, 0.45) 0%, #851A92 95.78%);
    clip-path: polygon(10px 0, 100% 0, 95% 100%, 0 100%);
}

.b-progress-bar-purple {
    background: linear-gradient(90deg, rgba(243, 185, 250, 0) 0.09%, #F3B9FA 74.29%);
}

.b-progress-bar-purple-1 {
    background: linear-gradient(240deg, rgba(243, 185, 250, 0) 0.09%, #F3B9FA 74.29%);
}

.b-progress-bar-orange {
    background: linear-gradient(90deg, #FFB0B0 0%, rgba(255, 176, 176, 0) 106.66%);
}

.b-no-scroll {
    overflow: inherit !important;
}

.home-banner .swiper-slide {
    @apply h-[285px] w-[506px];
}

@media (max-width: 768px) {
    .home-banner .swiper-slide {
        @apply h-[169px] w-[300px];
    }
}

.timezone__menu {
    width: 300px !important;
    z-index: 30 !important;
}

.timezone__menu-list::-webkit-scrollbar {
    width: 6px;
}

.timezone__menu-list::-webkit-scrollbar-track {
    background: var(--color-neutral-1-800);
    border-radius: 6px;
}

.timezone__menu-list::-webkit-scrollbar-thumb {
    background-color: var(--color-accent-1);
    border-radius: 6px;
}

.b-project-bg {
    background: linear-gradient(178.41deg, rgba(133, 26, 146, 0) 1.5%, rgba(133, 26, 146, 0.45) 98.81%);
}

.mobile-create-game {
    text-indent: 0;
    border-width: 2px;
}

.create-game-sliders {
    padding-bottom: 60px !important;
}

.create-game-sliders .swiper-slide {
    width: 293px;
    height: auto;

    filter: blur(2px);
}

.create-game-sliders .swiper-slide-active {
    filter: blur(0);
}

.create-game-sliders .swiper-pagination-bullet-active {
    @apply bg-primary;
}

.format-link a {
    @apply text-accent-1;
}


.format-link a:hover {
    @apply text-primary;
}

.ellips-2lines {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.referral-qa .faq-row-wrapper {
    padding-left: 42px !important;
    padding-right: 38px !important;
}

.referral-qa .faq-row-wrapper .faq-body .faq-row .row-title {
    position: relative;
}

.referral-qa .faq-row-wrapper .faq-body .faq-row .row-title-text {
    margin-left: 20px !important;
}

.referral-qa .faq-row-wrapper .faq-body .faq-row .row-content {
    margin-left: 20px !important;
}

.referral-qa .faq-row-wrapper .faq-body .faq-row .row-title .icon-wrapper {
    right: auto !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    margin-top: -4px !important;
}

@media (max-width: 768px) {
    .referral-qa .faq-row-wrapper {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .referral-qa .faq-row-wrapper .faq-body .faq-row .row-title {
        font-size: 14px !important;
    }

    .referral-qa .faq-row-wrapper .faq-body .faq-row .row-content .row-content-text {
        font-size: 12px !important;
    }
}


.sign-in-button button {
    background: none !important;
    color: #000 !important;
    text-align: center !important;
    font-family: Inter !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 14px !important;

    border-radius: 4px !important;
    border: 1px solid #F5B826 !important;
    background: #FFF !important;

    width: 63px !important;
    height: 23px !important;

    padding: 0 !important;
}