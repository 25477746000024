.sequence-show img:nth-child(1) {
    animation: xfade 6s 0s infinite;
}

.sequence-show img:nth-child(2) {
    animation: xfade 6s 1.5s infinite;
}

.sequence-show img:nth-child(3) {
    animation: xfade 6s 3s infinite;
}

.sequence-show img:nth-child(4) {
    animation: xfade 6s 4.5s infinite;
}

@keyframes xfade {
    17% {
        opacity: 1;
    }
    25% {
        opacity: 0;
    }
    92% {
        opacity: 0;
    }
}