.rmdp-container {
    display: block !important;
}

.rmdp-container > div {
    width: 100% !important;
}

.ep-arrow {
    display: none;
}

.rmdp-arrow-container:hover {
    box-shadow: none !important;
    background-color: transparent !important;
    opacity: .4;
}

.rmdp-arrow {
    border: solid var(--color-neutral-1) !important;
    border-width: 0 2px 2px 0 !important;
}

.rmdp-wrapper {
    width: 100% !important;
    font-family: Inter;
}

.rmdp-time-picker div input {
    font-family: Inter;
    width: 24px !important;
}

.rmdp-calendar {
    width: 100% !important;
}

.rmdp-day-picker > div {
    width: 100%;
}

.rmdp-day-picker .rmdp-week:first-child {
    position: relative;
}

.rmdp-day-picker .rmdp-week:first-child:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    border: 1px dashed var(--color-neutral-1-300);
}

.rmdp-day-picker .rmdp-week:nth-child(2) {
    margin-top: 6px;
}

.rmdp-day, .rmdp-week-day {
    width: 31px !important;
    height: 31px !important;
}

.rmdp-week-day {
    font-weight: 500 !important;
    font-size: 11px !important;
    line-height: 10px !important;

    color: var(--color-neutral-1-300) !important;
    text-transform: uppercase;
}

.rmdp-week .rmdp-day:last-child span {
    color: var(--color-accent-1);
}

.rmdp-day.rmdp-deactive {
    color: var(--color-neutral-1-300) !important;
}

.rmdp-day span {
    font-weight: 500 !important;
    font-size: 11px !important;
    line-height: 10px !important;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    background: var(--color-primary) !important;
    box-shadow: none !important;
    color: #fff !important;
}

.rmdp-day.rmdp-deactive.rmdp-selected span {
    background: var(--color-primary) !important;
    box-shadow: none !important;
    color: #fff !important;
}

.rmdp-day.rmdp-selected:not(.rmdp-day) span:not(.highlight) {
    background: var(--color-primary) !important;
    box-shadow: none !important;
    color: #fff !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
    background: var(--color-primary) !important;
    box-shadow: none !important;
    color: #fff !important;
}

.rmdp-day.rmdp-today span {
    background: var(--color-primary-100) !important;
    color: var(--color-primary-500) !important;
    box-shadow: none !important;
}