#range-slider .range-slider__thumb[data-lower] {
    width: 0;
}

#range-slider .range-slider__range {
    border-radius: 6px;
}

.range-slider {
    background: var(--color-neutral-1-300) !important;
    height: 4px !important;
}

.range-slider .range-slider__thumb {
    background: linear-gradient(90deg, #851A92 0%, #B70CCC 100%) !important;
    box-shadow: 0px 0px 0px 2px white inset !important;
    width: 26px;
    height: 26px;
}

.range-slider .range-slider__range {
    background: var(--color-primary) !important;
}